import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { H4 } from 'components/typography/Headers';
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'providers/ModalProvider';
import useMyCampaigns from 'services/Campaign/useMyCampaigns';

import CampaignTile from './components/campaign-tile/CampaignTile';
import { LoadMore } from './components/LoadMore';
import { NoContent } from './components/NoContent';
import { ReturnButton } from './components/ReturnButton';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 24px;

  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;

  ${theme.mq.phone} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const MyActivityCampaignsPage = () => {
  const { t } = useTranslation('myProfile');
  const { state, fetchNextPage } = useMyCampaigns();
  const { showGetApp } = useModal();
  const onCreateClick = () => showGetApp({ appAreaType: 'ADD_CAMPAIGN' });
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const renderNoContent = () => (
    <NoContent
      text={t('my-activity.no-campaigns')}
      buttonText={t('my-activity.create-first')}
      onClick={onCreateClick}
    />
  );

  const resolveButtonText = () => (isMobile ? t('my-activity.create') : t('my-activity.create-campaign'));

  const renderCampaigns = () => (
    <>
      <TilesContainer>
        {state.campaigns.map(campaign => (
          <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
        ))}
      </TilesContainer>
      <LoadMore hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
    </>
  );

  const renderContent = () => {
    if (state.loading) return null;
    if (state.campaigns && state.campaigns.length > 0) return renderCampaigns();
    return renderNoContent();
  };

  return (
    <div data-testid={'my-profile-my-activity-campaigns'}>
      <ReturnButton />
      <HeaderContainer>
        <H4>{t('my-activity.campaigns')}</H4>
        <CreateButton onClick={onCreateClick}>{resolveButtonText()}</CreateButton>
      </HeaderContainer>
      {renderContent()}
      <LoadMore hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
    </div>
  );
};
